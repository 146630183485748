<template>
  <div>
    <VueSlickCarousel
      v-if="dataSet && dataSet.length"
      v-bind="settings"
      ref="slider-carousel"
      :key="componentKey"
    >
      <div v-for="(item, index) in dataSet" :key="index">
        <img
          :src="item.url"
          :class="cssClass"
          :id="`image-id-${item.id}`"
          :alt="`image-${index}`"
          @click="selectedItem('selectedImage', item)"
          @load="imageLoaded(item)"
          @error="$event.target.src = backUpImage"
        />
        <label class="col-12" v-if="actionItem">
          <span class="d-flex justify-content-center action-item">
            <button
              class="action btn border py-1 px-2"
              target="_blank"
              rel="noopener noreferrer"
              :disabled="item.default_document"
              @click="selectedItem('makeItemAsDefault', item)"
              :class="{'bg-light': item.default_document}"
            >
              {{ item.default_document ? "Default" : "Make Default" }}</button
            >
            <button
              class="action btn border py-1 px-2"
              target="_blank"
              rel="noopener noreferrer"
              @click="selectedItem('deleteItem', item)"
              ><i class="fa-solid fa-trash-can"></i
            ></button>
          </span>
        </label>
      </div>
    </VueSlickCarousel>
  </div>
</template>
<script>
// For docs see - https://github.com/gs-shop/vue-slick-carousel
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "ImageSlider",
  components: {
    VueSlickCarousel,
  },
  props: {
    dataSet: {
      type: Array,
      default: () => [],
    },
    backUpImage: {
      type: String,
      default: "",
    },
    cssClass: {
      type: String,
      default: "",
    },
    defaultImageID: {
      type: Number,
      default: 0,
    },
    slidesToShow: {
      type: Number,
      default: 3,
    },
    slidesToScroll: {
      type: Number,
      default: 2,
    },
    actionItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      settings: {
        infinite: false,
        slidesToShow: this.slidesToShow,
        focusOnSelect: true,
        slidesToScroll: this.slidesToScroll,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: false,
              arrows: true,
            },
          },
          {
            breakpoint: 900,
            settings: {
              dots: false,
            },
          },
        ],
      },
      lastSelectedImage: {},
      componentKey: 0,
    };
  },
  methods: {
    selectedItem(action, item) {
      this.$emit(action, item);
      this.updateSelectedImage(item.id);
      this.lastSelectedImage = item;
    },
    updateSelectedImage(id) {
      let element = document.getElementById(
        `image-id-${this.lastSelectedImage.id}`
      );
      element?.classList.remove("selected");
      element = document.getElementById(`image-id-${id}`);
      element?.classList.add("selected");
    },
    imageLoaded(item) {
      if (item.id == this.defaultImageID) {
        this.updateSelectedImage(item.id);
        this.lastSelectedImage = item;
      }
    },
    forceRender() {
      this.componentKey += 1;
    },
  },
  watch: {
    dataSet() {
      this.lastSelectedImage = {};
      this.forceRender();
    },
    slidesToShow() {
      this.settings.slidesToShow = this.slidesToShow;
      this.forceRender();
    },
  },
};
</script>
<style lang="scss">
.slick-prev:before,
.slick-next:before {
  color: black !important;
}
.slick-slider img {
  padding: 2px;
}
.slick-slider img:hover {
  border: 1px solid darkgrey;
}
.selected {
  border: 1px solid #0094a8;
}
.job-template-image {
  height: 50px;
}
@media screen and (max-width: 768px) {
  .job-template-image {
    height: 30px;
  }
}
.double-image-slider {
  .job-picture-image {
    height: auto;
    max-height: 80px;
  }
}

.single-image-slider {
  .job-picture-image {
    height: auto;
    max-height: 90px;
  }
}

.action {
  font-size: 11px;
  margin-left: 3px;
  cursor: pointer;
}

// .slick-slide:hover .action-item {
//   display: block;
//   opacity: 1;
// }

.action-item {
  position: relative;
  padding: 8px;
}
</style>
